import React from "react";
import { graphql } from "gatsby";
import Layout from '../components/layout'

export default class PostTemplate extends React.Component {
  render() {
    const { slug } = this.props.pageContext;
    const postNode = this.props.data.markdownRemark;
    const post = postNode.frontmatter;
    if (!post.id) post.id = slug;
    return (
      <Layout location={this.props.location}>
        <article className="page">
          <div dangerouslySetInnerHTML={{ __html: postNode.html }} />
        </article>
      </Layout>
    );
  }
}

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
      }
      fields {
        slug
      }
    }
  }
`;
